import axios from 'axios';
import './PricingTable.scss';
import { useEffect, useState } from 'react';

const PricingTable = ({link}) => {

    const [expenses, setExpenses] = useState(true);
    const [expensesDiscounted, setExpensesDiscounted] = useState(null);
    const [discountLink, setDiscountLink] = useState(null);

    console.log(link)

    const dataFetch = async () => {
        try {

            const user = await axios.get('https://newbrains-edu.com/api/auth/userProfile', {
                headers: {
                'Authorization': `Bearer ${localStorage.getItem("heighNewbrainsToken")}`
                }
            })

            const {data} = await axios.get("https://newbrains-edu.com/api/settings");

            if(user?.data?.user.grade == 'third') {
                setExpenses(data.SecondaryGradethreeExpenses)
            } else {
                setExpenses(data.defaultGradeExpenses)
            }

        } catch (error) {
            console.log (error)
        }
        
    };

    const handleSubmit = async (e) => {

        e.preventDefault();

        const {data} = await axios({
            url:"https://newbrains-edu.com/api/coupon/validate",
            method: "POST",
            data:{
                code: e.target.querySelector('input[name="code"]').value 
            },
        });

        if(data?.status) {
            alert(data?.message)
            
            let coupon = data?.coupon

            if(coupon) {

                if(coupon.type == 'percentage') {
                    setExpensesDiscounted(expenses - (expenses * coupon.discount/100))
                } else {
                    setExpensesDiscounted(expenses - coupon.discount)
                }

                setDiscountLink(link + '?code=' + coupon.code)

            }

        } else {
            alert(data?.message)
        }

    };

    useEffect(() => {
        dataFetch();
    }, []);

    return (
        <>
            <form className='fs-5 fw-bold my-4 w-50 m-auto' onSubmit={handleSubmit}>
                <div className='form-group'>
                    <input className='form-control' style={{display:'inline-block'}} type="text" name='code' placeholder='Coupon Code'/>
                    <button className='btn btn-primary' type="submit">Apply Coupon</button>
                </div>
            </form>

            <section className="pricing-plans fs-5 fw-bold my-4 w-50 m-auto">
                <div className="pricing-card basic">
                    <div className="heading">
                        <h4>Full course</h4>
                        <p>for your year curriculums</p>
                    </div>
                    <p className="price">
                        {expensesDiscounted ?? expenses}EG
                        <sub>/Year</sub>
                    </p>
                    <ul className="features">
                        <li>
                            <i className="fa-solid fa-check"></i>
                            <strong>24/7</strong> support
                        </li>
                    </ul>
                    <a href={discountLink ?? link} target='_blank' className='btn btn-primary d-block'>Pay</a>
                </div>
            </section>   
        </>     
    )
}

export default PricingTable;